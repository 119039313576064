import { Link } from 'gatsby';
import React from 'react';
import logo from '../img/logo.svg';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

const Navbar = (props) => {
  const { pathname } = useLocation();

  return (
    <Nav className="level is-mobile is-align-items-flex-start" aria-label="main-navigation">
      <div className="level-left">
        <Link to="/" title="Logo">
          <img src={logo} alt="FilmChain" className="filmchain-icon" />
        </Link>
      </div>
      <div className="level-right">
        {pathname === '/' ? (
          <a href="/knowledge-hub" title="Knowledge Hub" className={`knowledge-hub level-item btn`}>
            Knowledge Hub
          </a>
        ) : null}
        {!props.contactus && (
          <Link to="/login" className={`level-item btn inverted`}>
            {' '}
            Log in
          </Link>
        )}
      </div>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  position: relative;
  z-index: 2;
  @media screen and (min-width: 769px) and (max-width: 1216px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media screen and (max-width: 769px) {
    &.level.is-mobile .level-item:not(:last-child) {
      margin: 10px 0;
    }

    &.level.is-mobile .level-right {
      flex-direction: column;
      flex-flow: column-reverse;
      align-items: flex-end;
      display: flex;
    }
  }
`;
