import PropTypes from 'prop-types';
import React from 'react';

const Term = ({ title, points, index }) => (
  <div className="container">
    <div className="columns">
      <div className="column is-4-tablet term-container">
        <div className="columns is-centered term-header-sticker">
          <div className="column is-6-tablet term-header">
            <h1 className="is-size-4" style={{ color: '#A7A6B3' }}>
              {index && (
                <>
                  {' '}
                  {`${index}.`} <br />{' '}
                </>
              )}
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="column is-5-tablet">
        <ul>
          {points.map((point, index) => (
            <li key={index} className="term-list-item-main">
              {point.mainPoint}
              {point.subpoints && (
                <ul style={{ paddingInlineStart: '1rem' }}>
                  {point.subpoints.map((subpoint, index) => (
                    <li key={index} className="term-list-item-sub">
                      {subpoint.subpoint}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

Term.propTypes = {
  title: PropTypes.string,
  points: PropTypes.array,
};

export default Term;
